@media (max-width:1500px) {
    .inner-filter-field {
        height: 73vh;
    }

    .right-side-cmn {
        width: calc(100% - 280px);
    }

    aside {
        width: 280px;
    }
}


@media (max-width:1400px) {
    .left-login-banner {
        width: 41%;
    }

    .left-login-banner img {
        width: 100%;
        max-width: 384px;
        object-fit: cover;
    }

    .right-banner-part {
        width: 50%;
        max-width: 395px;
    }

    .login-box-inner-wrap {
        padding: 71px 30px 40px 30px;
        overflow-y: scroll;
        min-height: 575px;
        scrollbar-width: none;
    }

    .sign-up-text {
        padding-top: 93px;
    }

    /* .footer-sec {
        position: inherit;
        bottom: 0px;
        margin-top: 23px;
    } */
    .inner-filter-field {
        height: 73vh;
    }

}