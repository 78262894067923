@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif !important;
}

.sign-banner-part {
  background: rgba(33, 56, 126, 0.06);
  height: 100vh;
}

.login-logo {
  text-align: center;
}

.center-align-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-width: 838px;
  margin: auto;
}

.left-login-banner {
  width: 50%;
}

.right-banner-part {
  width: 50%;
}

.login-box-inner-wrap {
  max-width: 550px;
  margin: auto;
  padding: 164px 40px 40px 40px;
  min-height: 700px;
  border-radius: 0px 24px 24px 0px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
}

.login-box-inner-wrap h2 {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.6px;
}

.login-box-inner-wrap p {
  font-size: 15px;
  font-weight: 400;
  color: #6e6b78;
  font-family: "Public Sans", sans-serif;
}

.login-box-inner-wrap form {
  margin-top: 35px;
}

.login-box-inner-wrap label {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 6px;
  height: 42px;
}

.login-box-inner-wrap input:focus {
  box-shadow: none;
  border-color: #d1d0d4;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.login-box-inner-wrap input::placeholder {
  color: #acaab1;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.remember-check {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.remember-check input {
  height: 16px;
  width: 16px;
}

.login-box-inner-wrap input {
  /* height: 42px; */
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  background: #fff;
}

.login-box-inner-wrap input::placeholder {
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.login-box-inner-wrap label {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.forgot {
  color: #121212;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.pass-rember-line {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 16px;
  gap: 15px;
}

.submit {
  margin-top: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  text-decoration: none;
  margin: auto;
  border-radius: 7px;
  border-radius: 6px;
  background: #21387e;
}

button.submit {
  outline: none;
  border: none;
}

.forgot-btn {
  margin-top: 30px !important;
}

.sign-up-text p {
  color: #696969;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.sign-up-text p a {
  color: #121212;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

.sign-up-text {
  text-align: center;
  padding-top: 150px;
}

.pass-eys {
  position: relative;
}

.pass-eys img {
  position: absolute;
  top: 12px;
  right: 19px;
  cursor: pointer;
}

/* sign up  */
.sign-up-box {
  padding: 25px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.terms-conditions p {
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.terms-conditions p span {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  text-decoration-line: underline;
}

.btn-next .submit {
  margin-top: 30px !important;
}

.login-text {
  text-align: center;
  padding-top: 18px;
}

/*page setup with side bar */
.bar_active path {
  fill: #fff;
}

.main-wrap {
  display: flex;
  height: 100vh;
}

aside {
  width: 300px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}

.logo-side {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-side img {
  width: 140px;
  margin: 25px 23px;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
  margin-top: 10px;
}

.side-menu .side-btm-space {
  margin-bottom: 5px;
}

.side-menu a {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.side-menu a p {
  margin: 0;
}

.side-pages {
  padding: 10px 25px;
}

.side-pages p {
  margin: 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #acaab1;
}

.side-menu a.bar_active {
  color: #ffffff;
}

.side-menu a.bar_active {
  color: #ffffff;
  border-radius: 6px;
  background: #21387e;
  margin: 0px 10px;
  padding: 12px 15px;
}

.side-menu a svg {
  margin-right: 13px;
  width: 20px;
}

.side-menu a {
  display: flex !important;
  padding: 10px 25px;
  text-decoration: none;
  color: #696969;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  font-weight: 500 !important;
  align-items: center;
  font-size: 14px;
}

.right-side-cmn {
  width: calc(100% - 300px);
  position: relative;
  margin-left: auto;
  background: #fafafa;
  height: 100vh;
  position: relative;
}

.right-top-bar {
  padding: 12px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
}

.left-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-side-bar input {
  border: 0 !important;
  background-color: transparent;
  outline: none !important;
}

.notification-icon:before {
  position: absolute;
  content: "";
  background: #e5e5e5;
  height: 34px;
  width: 1px;
  right: -15px;
  top: -4px;
}

.notification-icon {
  position: relative;
}

.dropdown-toggle::after {
  position: absolute;
}

.dropdown-menu.show {
  width: 230px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  border: none;
  padding: 10px;
}

a.logout-btn.dropdown-item {
  color: #f00;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.user-img-top .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.my-profile a {
  display: flex;
  align-items: center;
  gap: 7px;
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.right-side-bar {
  display: flex;
  align-items: center;
  gap: 30px;
  /* width: 100%;
	max-width: 186px; */
}

.user-profile-icon {
  display: flex;
  align-items: center;
  gap: 5px;
  /* width: 150px; */
}

.user-profile-icon p {
  color: #121212;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 0px;
}

.user-img-top button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  /* width: 50px; */
  border-radius: 50%;
  height: 50px;
}

.right-top-bar .user-img-top img {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.drop-pro-view {
  display: flex;
  gap: 5px;
  align-items: center;
}

.right-top-bar .user-img-top img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.admin-name h3 {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: 0px;
}

.admin-name p {
  font-size: 13px;
  font-weight: 400;
  color: #acaab1;
  line-height: 20px;
  margin: 0px;
}

.left-side-bar button {
  border: none;
  outline: none;
}

.user-search {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  margin-top: 21px;
  padding: 20px;
  max-width: 708px;
  margin-left: 9px;
}

.main-heading h2 {
  color: #121212;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}

.edit-flex-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-profile-image p {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  margin: 0px;
}

.user-profile-image img {
  width: 80px;
  border-radius: 50%;
  height: 80px;
}

.user-profile-image {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.user-info input:focus {
  box-shadow: none;
  border-color: #d1d0d4;
}

.user-info input {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 42px;
}

.modal-main-heading h1 {
  color: #121212;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0px;
}

.modal-main-heading {
  text-align: center;
  margin-top: 33px;
}

.updated-modal .modal-header {
  padding: 0px;
  border-bottom: 0px;
}

.updated-modal button.btn-close {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 1111;
}

.modal-main-heading p {
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 14px;
}

.updated-modal .modal-footer {
  border: none;
  justify-content: center;
  margin-bottom: 13px;
}

.footer-button button.btn-secondary {
  border-radius: 6px;
  background: #21387e;
  width: 48%;
}

.footer-button button.btn.btn-primary {
  border-radius: 6px;
  border: 1px solid #21387e;
  background: #fff;
  width: 48%;
  color: #21387e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.new-popover .popover-arrow {
  display: none !important;
}

.new-popover h3.popover-header {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border: none;
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 14px;
  width: 222px;
  cursor: pointer;
}

.div#popover-basic {
  border: none !important;
}

button.btn.btn-secondary.done-modal-btn {
  width: 100%;
}

.delete-opt img {
  cursor: pointer;
}

.input-number {
  position: relative;
}

.new-select-box {
  position: absolute;
  top: 2px;
  left: 2px;
}

.drop-with-number input {
  padding-left: 124px;
}

.new-select-box select {
  border: none;
}

.new-select-box select {
  color: #121212;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  border-right: 1px solid #e5e5e5;
  border-radius: 0px;
}

/* end */

.bank-details-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  flex-direction: column;
}

.user-info {
  width: 100%;
}

.green-icon {
  text-align: center;
}

.text-heading h1 {
  text-align: center;
  color: #121212;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-heading p {
  color: #696969;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  margin: 0px;
}

button.submit.add-to-cart {
  border-radius: 6px;
  border: 1px solid #21387e;
  background: #fff;
  color: #21387e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cart-bottom-btn {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
}

.image-heading-text {
  padding: 23px 0px 0px 23px;
  background: #fff;
  border-radius: 6px 6px 0px 0px;
}

.filters {
  margin-left: 10px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  border-radius: 6px;
  margin-top: 20px;
}

.user-search-passengers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 22px 0px;
}

.toggle-box-top {
  display: flex;
  gap: 5px;
  align-items: center;
}

.toggle-btn {
  cursor: pointer;
}

.toggle-active p {
  color: #21387e !important;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.toggle-btn p {
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
  display: flex;
  gap: 7px;
  align-items: center;
  padding-right: 24px;
  padding-bottom: 11px;
}

.search-tab {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 11px 22px 9px 22px;
  justify-content: space-between;
}

.center-bank-details {
  max-width: 638px;
  width: 100%;
}

.otp-varification-page {
  margin: auto;
  padding: 40px 40px 40px 40px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);
}

.otp-heading h1 {
  color: #121212;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.otp-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.table-responsive {
  overflow-x: auto;
  margin: 22px;
  border: 1px solid #e6e5e7;
  border-radius: 6px;
  /* height: 500px;
	overflow: auto;
	scrollbar-width: thin; */
}

.table-responsive table {
  margin: 0px;
  /* position: relative; */
}

.table-responsive thead tr th {
  padding: 14px 20px;
  position: relative;
  border-right: 1px solid #ececec;
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #f4f4f4;
  /* position: sticky;
	top: 0; */
}

.table-responsive tbody tr td {
  /* max-width: 124px; */
  padding: 14px 20px;
  border-right: 1px solid #ececec;
  vertical-align: middle;
}

.image-heading-text h2 {
  color: #121212;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 14px;
}

.input-search {
  width: 100%;
  position: relative;
}

.search-icon {
  position: absolute;
  top: 6px;
  left: 12px;
}

.input-search input {
  padding-left: 36px;
  color: #696969;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.input-search .form-control:focus {
  border-color: inherit !important;
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
}

.filter-btn button {
  border-radius: 6px;
  background: #21387e;
  padding: 9px 19px;
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  outline: none;
}

.drop-down {
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
}

.toggle-active::before {
  content: "";
  width: 59px;
  height: 2px;
  position: absolute;
  background: #21387e;
  bottom: -1px;
}

.toggle-active {
  position: relative;
}

.inner-filter-field {
  padding-bottom: 5px;
  height: 80vh;
  overflow: auto;
  scrollbar-width: thin;
}

.table-responsive tbody tr td:last-child {
  border-right: 0px;
}

.table-responsive tbody tr:last-child {
  border-bottom: transparent;
}

.table-responsive thead tr th:last-child {
  border-right: 0px;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-id p {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.rating {
  display: flex;
  gap: 2px;
}

.user-view {
  display: flex;
  gap: 7px;
  cursor: pointer;
}

.full-input-width {
  width: 100% !important;
}

.footer-sec p {
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}

.footer-sec {
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  padding: 22px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-top: 23px;
}

.dropdown-box {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.8s ease;
}

.dropdown-box.show {
  max-height: 200px;
  /* Adjust to fit your content */
  opacity: 1;
}

.icon-and-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-drop-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdown-box ul li {
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  cursor: pointer;
}

.dropdown-box ul {
  margin-top: 10px;
  padding-left: 50px;
}

.dropdown-box ul li::marker {
  font-size: 20px;
  line-height: 25px;
}

.active-btn {
  color: #21387e !important;
  font-family: Montserrat;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 0;
}

.user-profile img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.pagination-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #ececec;
}

.showing-user p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #acaab1;
  margin: 0px;
}

.pagination-block ul {
  margin: 0px !important;
  gap: 6px;
}

.pagination-block a.page-link {
  background: #efeef0;
  border-radius: 6px;
  color: #423f4f;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
  padding: 0px;
}

.pagination-block li.page-item a span {
  font-size: 25px;
}

.pagination-block li.page-item.active .page-link {
  background: #21387e;
  border: 1px solid #21387e;
  border-radius: 6px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
}

.pagination-block .page-link:focus {
  box-shadow: none !important;
}

.pagination-block li.page-item:hover a {
  background: #e8e6fc;
  color: #7367f0;
}

.driver-prof {
  display: flex;
  justify-content: space-between;
}

.profile-image {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 11px;
}

.profile-image h2 {
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;
  color: #121212;
  margin: 0px;
}

.driver-add p {
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  color: #121212;
  margin-top: 10px;
  margin-bottom: 0px;
}

.driver-add p span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #696969;
  margin: 0px;
}

.approve-btn button {
  width: 230px;
  height: 40px;
}

.approve-btn button {
  background: #21387e;
  color: #fff;
  padding: 6px 40px;
  border-radius: 6px;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  min-width: 184px;
}

.approve-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}

.approve-btn button:last-child {
  background: transparent;
  border: 1px solid #ff0000;
  color: #ff0000;
}

.driver-new-req-det {
  padding: 25px;
}

.documents-add h2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #21387e;
  margin: 0px;
}

.documents-add {
  background: #21387e1a;
  padding: 10px;
  border-radius: 4px;
  margin-top: 16px;
}

.driver-documents-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 19px;
}

.documents-count {
  border: 1px solid #e5e5e5;
  text-align: center;
  border-radius: 6px;
  padding: 16px;
}

.documents-count p {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #121212;
  margin: 0px;
  margin-top: 13px;
}

.car-driving-box h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #121212;
  margin: 0px;
}

.car-driver {
  margin-top: 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.car-driver-ad-v {
  margin-top: 27px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: stretch;
}

.car-name p {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #121212;
  margin: 0px;
}

.car-name span {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #696969;
  margin: 0px;
}

.car-name {
  margin-top: 13px;
}

.car-name {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.car-driving-box {
  width: 50%;
  padding: 15px;
  border-radius: 16px;
  background: #fff;
}

.profile-image img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.car-owner {
  display: flex;
  align-items: center;
  gap: 10px;
}

.car-owner img {
  width: 39px;
  height: 39px;
  object-fit: cover;
}

.total-earnings-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 20px;
  gap: 10px;
}

.earnings-text h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #121212;
  margin: 0px;
}

.earnings-text p {
  font-size: 32px;
  font-weight: 600;
  line-height: 24px;
  color: #21387e;
  margin: 0px;
  padding-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.earnings-text {
  background: #fff;
  padding: 41px 0px;
  text-align: center;
  border-radius: 16px;
}

.user-id.locaton p {
  display: flex;
  align-items: center;
  gap: 7px;
}

.locaton {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.review-heading h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #121212;
  margin: 0px;
  margin-bottom: 12px;
}

.review-heading h3 {
  font-size: 40px;
  font-weight: 600;
  line-height: 24px;
  color: #696969;
  margin: 0px;
  display: flex;
  align-items: center;
  gap: 9px;
}

.reviews {
  margin-top: 20px;
  padding: 20px 20px;
  background: #fff;
  border-radius: 16px;
}

.driver-name-pic img {
  width: 44px;
  height: 44px;
  object-fit: cover;
}

.driver-reviews {
  border: 1px solid #e5e5e5;
  padding: 15px;
  border-radius: 12px;
}

.driver-name-pic h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #121212;
  margin: 0px;
}

.driver-name-pic {
  display: flex;
  gap: 10px;
  align-items: center;
}

p.reviews-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #696969;
  margin-top: 4px;
  margin-bottom: 0px;
}

.driver-filter {
  margin-left: 10px;
  margin-top: 20px;
}

.driver-page-bg {
  background: #fff;
  border-radius: 16px;
}

.table-trips {
  margin-top: 24px;
  background: #fff;
  padding-bottom: 10px;
  border-radius: 16px;
}

.review-heading h3 img {
  width: 40px;
}

.documents-count img {
  width: 100%;
  height: 300px !important;
  max-width: 240px !important;
  object-fit: cover;
}
.documents-count svg {
  width: 90px;
}

.account-number-text {
  margin-top: 1px;
}

.bank-det-flex {
  display: flex;
  gap: 10px;
  align-items: center;
  gap: 14px;
}

.map-image-sec img {
  width: 100%;
  height: 198px;
}

.plat-form-fee {
  border-radius: 16px;
  background: #21387e;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 170px;
  margin: 30px 0px;
}

.plat-form-fee p {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.plat-form-fee h3 {
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.plat-form-fee span {
  color: #fff;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.active-fleet {
  margin-top: 20px;
  padding: 20px;
}

.img-show-pop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 10px; */
  position: relative;
}

.img-show-pop > div {
  display: flex;
  justify-content: end;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
}

.img-show-pop img {
  height: 400px;
  width: 100%;
  margin: auto;
  object-fit: contain;
  background-color: #fff;
  padding: 50px;
  border-radius: 20px;
}

.img-show-pop > div svg {
  background-color: #fff;
  padding: 5px;
  box-sizing: content-box;
  border-radius: 5px;
  cursor: pointer;
}

.pop-trans-set .modal-content {
  background-color: transparent !important;
  border: 0 !important;
  width: 560px;
  margin: auto;
}

.img-show-pop > div button {
  border: 0;
  background-color: transparent;
}

.edit-input .form-select {
  color: #696969;
  height: 42px;
}

/*  */

.earnings-check {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 20px;
  margin-left: 10px;
}

.earnings-boxs {
  border-radius: 16px;
  background: #21387e;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0px;
  box-sizing: border-box;
}

.earnings-boxs p {
  color: var(--Misc-bg-white, #fff);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.earnings-boxs h2 {
  color: var(--Misc-bg-white, #fff);
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.first-earnings-boxs {
  border-radius: 16px;
  border: 1px solid #21387e;
  background: #fafafa;
}

.first-earnings-boxs p {
  color: #21387e;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.first-earnings-boxs h2 {
  color: #21387e;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.earnings-boxs h2 span {
  color: #fff;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.first-earnings-boxs h2 span {
  color: #21387e;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.term-conditions-data p {
  color: #696969;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.term-conditions-data {
  padding: 0px 23px;
}

.image-heading-text button {
  border-radius: 6px;
  border: 1px solid #21387e;
  background: #fff;
  padding: 5px 10px;
  color: #21387e;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 38px;
  width: 71px;
}

.term-conditions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 23px;
}

.term-conditions h2 {
  margin: 0px;
}

.term-conditions {
  margin-bottom: 29px;
}

.new-deriver-info-box {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
  padding: 25px;
  margin-top: 25px;
  margin-left: 10px;
}

.fleet-owner-request {
  padding-bottom: 5px;
  height: 85vh;
  overflow: auto;
  scrollbar-width: thin;
}

.total-vehicles-req {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.total-vehicles-req h3 {
  color: #121212;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.total-vehicles-req p {
  color: #21387e;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px;
}

.total-vehicles-req p span {
  cursor: pointer;
}

.set-prize-vehicles {
  padding: 25px;
  width: 80%;
}

.vehicles-prize-heading h2 {
  color: #121212;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vehicles-full-disp {
  margin-top: 30px;
}

.enter-price-field input {
  border-radius: 6px;
  border: 1px solid #e5e5e5;
  background: #fff;
  padding: 10px;
  width: 100%;
}

.enter-price-field input::placeholder {
  color: #696969;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.enter-price-field span {
  position: absolute;
  top: 12px;
  right: 10px;
  color: #121212;
  text-align: right;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.enter-price-field .user-info {
  position: relative;
}

.vehicles-info-pk {
  margin-bottom: 15px;
}

.earnings-text p span {
  cursor: pointer;
}

.table-responsive thead tr th {
  background: #f4f4f4;
  border-right: 1px solid #ececec;
  color: #696969;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 14px 20px;
  position: relative;
  vertical-align: initial;
}

.earnings-heading > tr {
  border-top: 1px solid #ececec !important;
  text-align: center;
}

.earnings-heading {
  padding: 0 !important;
  width: 100px;
}

.earnings-heading {
  text-align: center;
}

.ad-edit-pro {
  display: flex;
  align-items: center;
  gap: 5px;
}
.ad-edit-documents {
  position: relative;
}

.ad-edit-documents div {
  background-color: #21387e;
  padding: 0px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.ad-edit-pro > div {
  background-color: #21387e;
  padding: 0px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.ad-edit-pro > div svg {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.currently-assigned-rating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.currently-assigned-rating img {
  height: 15px;
  width: 15px;
}

.user-id p {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
}
